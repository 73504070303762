// Typography
// --------------------------------------------------
//@import "compass/css3/font-face";

	@font-face{
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Regular-webfont.eot');
	src: url('../fonts/Roboto-Regular-webfont.eot?iefix') format('eot'),
	     url('../fonts/Roboto-Regular-webfont.woff') format('woff'),
	     url('../fonts/Roboto-Regular-webfont.ttf') format('truetype'),
	     url('../fonts/Roboto-Regular-webfont.svg#webfont') format('svg');
        font-weight:normal;
}
@font-face{
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Bold-webfont.eot');
	src: url('../fonts/Roboto-Bold-webfont.eot?iefix') format('eot'),
	     url('../fonts/Roboto-Bold-webfont.woff') format('woff'),
	     url('../fonts/Roboto-Bold-webfont.ttf') format('truetype'),
	     url('../fonts/Roboto-Bold-webfont.svg#webfont') format('svg');
        font-weight:bold;
}
