// Styles for main layout
// --------------------------------------------------
.not-front #main-wrapper{margin-top:100px}
.logged-in .navbar-fixed-top{top:80px}
#header {position: fixed;
    width: 100%;
    z-index: 1001;
    }
.navbar-brand{padding-top:3px}
.vertical-m1{margin-bottom:14px}
.vertical-m2{margin-bottom:28px}
.vertical-m3{margin-bottom:42px}
.vertical-m4{margin-bottom:56px}
